import React, { Suspense, useEffect, useState } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import getNewAccessToken from '../../api/login/getNewAccessToken';
import axiosInstance from '../../axiosInstance';
import AuthContext from '../../contexts/AuthContext/AuthContext';
import {
  getAuthData,
  getAuthorizationHeaderValue,
  setAuthData,
  setLoggedInFlag,
  updateAuthTokenOnly,
} from '../../globalServices/auth/authService';
import { generateRedirectUrl } from '../../globalServices/helpers/redirectingHelper';
import RootLoader from './RootLoader';
import SVPlayRootLoader from './SVPlayRootLoader';
// code split routes lazy loading
const Studio = React.lazy(() => import('./Studio/Studio'));
const Logout = React.lazy(() => import('./Logout/Logout'));
const Basic = React.lazy(() => import('./Basic/Basic'));

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Root({ mode = 'BASIC' }) {
  const query = useQuery();
  const [loggedIn, setLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [redirectUrl, setRedirectUrl] = useState('');

  // optional query param that controls
  // if the redirect url should add credentials;
  // default behaviour: add credentials always.
  const [shouldAddCredentials, setShouldAddCredentials] = useState(true);

  // --------- read redirect url -----------

  useEffect(() => {
    // const url = 'www.google.com/';
    const url = query.get('redirect_url');

    setShouldAddCredentials(query.get('add_credentials') !== 'false');
    setRedirectUrl(url);
  }, []);

  // -------------- axios setup ---------------------

  const setupAxiosInterceptors = () => {
    // =================== auth / request  interceptors =============================

    // Add a request interceptor to add the jwt token
    axiosInstance.interceptors.request.use(
      config => {
        // eslint-disable-next-line no-param-reassign
        config.headers = {
          ...config.headers,
          Authorization: getAuthorizationHeaderValue(),
        };
        return config;
      },
      error => {
        return Promise.reject(error);
      },
    );

    // Add a response interceptor to handdle unauthorized requests
    axiosInstance.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        if (error.response) {
          const { status } = error.response;

          if (status === 403 || status === 500) {
            console.log('access denied');
          }
          if (status === 401) {
            // showMessageDialog('ERROR', 'Access Denied');
            // setLoginStatusAndToken(false);
          }
          console.log(error.response.data.error.errorMessage);
        }

        let rejectError;
        try {
          rejectError = new Error(error.response.data.error.errorMessage);
        } catch (err) {
          rejectError = error;
        }
        return Promise.reject(rejectError);
      },
    );
  };

  useEffect(() => {
    setupAxiosInterceptors();
  }, []);

  // ---------- auth checks ---------------------------

  const checkInitialLoggedIn = async () => {
    const { isLoggedIn } = getAuthData();
    if (isLoggedIn) {
      try {
        const { refreshId, refreshToken } = getAuthData();
        const newAccessToken = await getNewAccessToken({
          refreshId,
          refreshToken,
        });
        updateAuthTokenOnly(newAccessToken);
        setAuthData({ authToken: newAccessToken, refreshId, refreshToken });
      } catch (error) {
        console.log(error);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const shouldPerformInitialCheck = !window.location.pathname.includes(
      'logout',
    );

    if (shouldPerformInitialCheck) {
      checkInitialLoggedIn();
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (loggedIn && redirectUrl) {
      const { authToken, refreshId, refreshToken } = getAuthData();

      const redirectTo = generateRedirectUrl({
        initialUrl: redirectUrl,
        authToken,
        refreshId,
        refreshToken,
      });

      window.location.href = shouldAddCredentials ? redirectTo : redirectUrl;
    }
  }, [loggedIn, shouldAddCredentials, redirectUrl]);

  const setAuthCredentials = ({ authToken, refreshToken, refreshId }) => {
    setAuthData({ authToken, refreshId, refreshToken });
  };

  const handleLoginComplete = () => {
    setLoggedInFlag(true);
    setLoggedIn(true);
  };

  const handleLogout = () => {
    setLoggedInFlag(false);
    window.location.href = redirectUrl;
  };

  if (isLoading) {
    return mode === 'SV_PLAY' ? <SVPlayRootLoader /> : <RootLoader />;
  }

  if (!redirectUrl) {
    return (
      <div>
        pass redirect_url query parameter that is encoded using
        encodeURIComponent
      </div>
    );
  }

  if (!loggedIn) {
    return (
      <>
        <AuthContext.Provider
          value={{
            isLoggedIn: loggedIn,
            setAuthCredentials,
            setAsLoggedIn: handleLoginComplete,
            logout: handleLogout,
          }}
        >
          <Suspense
            fallback={mode === 'SV_PLAY' ? SVPlayRootLoader : RootLoader}
          >
            <Switch>
              <Route path="/logout" component={Logout} />
              <Route path="/studio" component={Studio} />
              <Route path="/basic" component={Basic} />
              <Redirect path="/" to="/basic" />
            </Switch>
          </Suspense>
        </AuthContext.Provider>
      </>
    );
  }

  if (loggedIn) {
    return (
      <>
        <AuthContext.Provider
          value={{
            isLoggedIn: loggedIn,
            setAuthCredentials,
            setAsLoggedIn: handleLoginComplete,
            logout: handleLogout,
          }}
        >
          <Suspense
            fallback={mode === 'SV_PLAY' ? SVPlayRootLoader : RootLoader}
          >
            <Switch>
              <Route path="/logout" component={Logout} />
              <Route
                path="/"
                component={mode === 'SV_PLAY' ? SVPlayRootLoader : RootLoader}
              />
            </Switch>
          </Suspense>
        </AuthContext.Provider>
      </>
    );
  }
}

export default Root;
