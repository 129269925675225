const IS_TEST = false;

const BASE_URL_TEST = 'https://interapis.test.sportvot.com';
const BASE_URL_PROD = 'https://interapis.sportvot.com';
export const BASE_URL = IS_TEST ? BASE_URL_TEST : BASE_URL_PROD;

// ignore this
export const DUMMY = 'ignore this...just so that linter does not complain';

export const COOKIES_ROOT_DOMAIN = '.sportvot.com';

export const LOGO =
  'https://fhp-news-bucket.s3.amazonaws.com/sv_web_30_jun_21/horizontal_logo.png';

export const S3_BUCKET_BASE_URL = 'https://s3.amazonaws.com/fhp-news-bucket';
export const DEFAULT_PROFILE_IMAGE_CHANNELS = `${S3_BUCKET_BASE_URL}/a73b3c42-6991-475f-8d6a-ce016d8e61f6.png`;

export const BROADCASTER =
  'https://fhp-news-bucket.s3.amazonaws.com/4bfa44a8-d5a9-4cb6-896f-c7d573e732a0.png';

export const SPORTING_ORGANIZATION =
  'https://fhp-news-bucket.s3.amazonaws.com/63f1d8a0-3458-4761-8985-f14de0545da5.png';

// -------------- cookies config ------------
// determines the cookie that is set by on successful sign-in

const COOKIE_REFRESH_ID_TEST = 'acc_sv_test_refreshId';
const COOKIE_REFRESH_ID_PROD = 'acc_sv_refreshId';
export const COOKIE_REFRESH_ID = IS_TEST
  ? COOKIE_REFRESH_ID_TEST
  : COOKIE_REFRESH_ID_PROD;

const COOKIE_REFRESH_TOKEN_TEST = 'acc_sv_test_refreshToken';
const COOKIE_REFRESH_TOKEN_PROD = 'acc_sv_refreshToken';
export const COOKIE_REFRESH_TOKEN = IS_TEST
  ? COOKIE_REFRESH_TOKEN_TEST
  : COOKIE_REFRESH_TOKEN_PROD;

const COOKIE_PROFILE_TYPE_TEST = 'acc_sv_test_profile_type';
const COOKIE_PROFILE_TYPE_PROD = 'acc_sv_profile_type';
export const COOKIE_PROFILE_TYPE = IS_TEST
  ? COOKIE_PROFILE_TYPE_TEST
  : COOKIE_PROFILE_TYPE_PROD;

const SV_PLAY_URL_TEST = 'https://sv-play-web-app2.sportvot.com/app';
const SV_PLAY_URL_PROD = 'https://play.sportvot.com/app';
export const SV_PLAY_URL = IS_TEST ? SV_PLAY_URL_TEST : SV_PLAY_URL_PROD;
